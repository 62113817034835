<template>
  <draggable class="dragArea" tag="ul" :list="categories">
    <li v-for="cat in categories" :key="cat.id" :class="{ single: sigleCategory }">
      <p>{{ cat.name }}</p>
      <nested-draggable v-if="cat.children_nodes" :categories="cat.children_nodes" />
    </li>
  </draggable>
</template>
<script>
import draggable from "vuedraggable";
export default {
  name: "nested-draggable",
  props: {
    categories: {
      required: true,
      type: Array
    }
  },
  components: {
    draggable
  },
  computed: {
    sigleCategory() {
      return this.categories.length === 1;
    }
  }
};
</script>
<style scoped lang="scss">
.dragArea {
  & > li {
    padding-right: 20px;
    // box-shadow: 0 4px 8px 0 rgba(0,0,0,0.12), 0 2px 4px 0 rgba(0,0,0,0.08);
    border: 1px solid rgba(0, 0, 0, 0.12);
    margin-bottom: 15px;
    width: 100%;
    padding: 10px;
    border-radius: 8px;
    display: flex;
    min-width: 60px;
    flex-direction: column;
    border-right: 3px solid rgba(30, 143, 255, 0.39);
    &[draggable="true"] {
      box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.11), 0 5px 15px 0 rgba(0, 0, 0, 0.08);
      border: 2px dashed dodgerblue;
      border-right: 3px solid rgb(30, 143, 255);
      font-weight: bold;
    }
    // &.single {
    //   border: 1px dashed rgba(0,0,0,0.12);
    // }
    p {
      margin-bottom: 5px;
    }
  }
}
</style>
