<template>
  <div class="m-categories rtl" style="postition: relative; margin-bottom: 100px;">
    <loading-spinner v-if="fetching || loading" position="absolute"></loading-spinner>
    <!-- <p class="section-header">معلومات المنتج</p> -->

    <div class="taps-container" style=" direction: ltr;">
      <div class="m-tab-nav rounded-corners">
        <div
          v-for="(option, index) in tabs"
          :key="option.title"
          class="m-tab rounded-corners"
          :class="{ selected: option.selected }"
          @click="onTabChanged(index)"
        >
          <span>{{ option.title }}</span>
          <!-- <i :class="option.icon"></i> -->
          <font-awesome-icon :icon="option.icon" />
        </div>
      </div>
    </div>

    <div v-if="tabs[1].selected" class="tab-section">
      <!-- <p class="m-section-title">قائمة الفئات</p> -->
      <button
        @click="
          () => {
            $router.push({ name: 'chatbot.category' });
          }
        "
        class="mujeeb-button icon-on-right green ltr"
      >
        <font-awesome-icon icon="plus" />إضافة فئة جديدة
      </button>

      <div class="m-items-table" v-if="categories != null && categories.length > 0">
        <div class="thead">
          <div class="tr">
            <div
              class="th"
              v-for="(property, index) in $store.getters['recipesConfig/categoriesProps']"
              :class="getCssClass(property.cssClass)"
              :key="index"
            >
              {{ property.title }}
            </div>
          </div>
        </div>
        <div class="tbody">
          <div class="tr" v-for="(category, index) in categories" :key="index">
            <div
              class="td"
              v-for="(property, index) in $store.getters['recipesConfig/categoriesProps']"
              :key="index"
              :data="property.title"
              :class="getCssClass(property.cssClass)"
            >
              <span v-if="property.name === 'name'">{{ category.name }}</span>
              <span v-else-if="property.name === 'itemsIncluded'">{{
                category.itemsIncluded
              }}</span>
              <b-switch
                v-else-if="property.name === 'published'"
                :value="category.published"
                v-model="category.published"
                @input="toggleCategory($event, category)"
              ></b-switch>
              <template v-else>
                <button class="m-edit" data="تعديل" @click="editCategory(category.id)">
                  <font-awesome-icon icon="edit" />
                </button>
                <button class="m-delete" data="حذف" @click="confirmDeletion(category)">
                  <font-awesome-icon icon="trash-alt" />
                </button>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div v-else style="border: 1px dashed black; border-radius: 6px;">
        <p class="empty-table-msg">لم يتم إدخال فئات حتى الآن</p>
      </div>
    </div>

    <div v-if="tabs[0].selected" class="tab-section">
      <div v-if="rootCategories.length > 0">
        <nestedDraggable :categories="rootCategories" :level="0"></nestedDraggable>
        <div
          v-if="categories.length > 0"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <button class="mujeeb-button" @click="onTabChanged(1)">عودة</button>
          <button class="mujeeb-button green" @click="updateCategoriesIndecies">تحديث</button>
        </div>
      </div>
      <div v-else>
        <div style="border: 1px dashed black; border-radius: 6px;">
          <p class="empty-table-msg">لم يتم إدخال فئات حتى الآن</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import nestedDraggable from "./../utilityComponents/nested-draggable";

export default {
  name: "categories-table",
  components: {
    nestedDraggable
  },
  data() {
    return {
      tabs: [
        {
          title: "ترتيب الفئات",
          icon: "sort",
          selected: false,
          name: "extras"
        },
        {
          title: "الفئات",
          icon: "plus",
          selected: true,
          name: "item"
        }
      ],
      categories: [],
      rootCategories: [],
      fetching: false,
      loading: false
    };
  },
  methods: {
    onTabChanged(index) {
      this.tabs.map((option, i) => {
        if (i === index) {
          option.selected = true;
        } else {
          option.selected = false;
        }
      });
    },
    editCategory(id) {
      this.$router.push({ name: "chatbot.category", params: { categoryId: id } });
    },
    confirmDeletion(category) {
      this.$buefy.dialog.confirm({
        title: `حذف فئة ${category.name}؟`,
        message: `كن حذرا، فعند حذف هذه الفئة سيتم حذف كل الفئات التابعة لها`,
        confirmText: "متأكد",
        cancelText: "كلا",
        type: "is-danger",
        onConfirm: () => {
          this.deleteCategory(category.id);
        }
      });
    },
    deleteCategory(id) {
      this.loading = true;
      const categoryIndex = this.categories.findIndex(cat => cat.id === id);
      this.$api
        .customRequest({
          method: "delete",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${id}`,

          responseType: "json"
        })
        .then(({ data }) => {
          this.$delete(this.categories, categoryIndex);
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم حذف الفئة المختارة",
            position: "is-top",
            type: "is-success"
          });
          this.getCategories();
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء حذف الفئة",
            position: "is-top",
            type: "is-success"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCssClass(cssClass) {
      const temp = {};
      temp[`item-width-${this.$store.getters["recipesConfig/categoriesProps"].length}`] = true;
      if (cssClass) {
        temp[cssClass] = true;
      }
      return temp;
    },
    updateCategoriesIndecies() {
      this.loading = true;
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories`,

          responseType: "json",
          data: { categories: this.rootCategories }
        })
        .then(({ data }) => {
          this.$buefy.toast.open({
            duration: 3000,
            message: "تم تحديث ترتيب الفئات بنجاح",
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 3000,
            message: "حدث خطأ أثناء ترتيب الفئات",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    toggleCategory(value, category) {
      this.loading = true;
      this.$api
        .customRequest({
          method: "put",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories/${category.id}/published`,

          responseType: "json",
          data: {
            published: value
          }
        })
        .then(({ data }) => {
          console.log(data);
          this.$buefy.toast.open({
            duration: 2000,
            message: "تم تحديث الفئة المختارة",
            position: "is-top",
            type: "is-success"
          });
        })
        .catch(error => {
          console.log(error);
          this.$buefy.toast.open({
            duration: 2000,
            message: "حدث خطأ أثناء تحديث الفئة",
            position: "is-top",
            type: "is-danger"
          });
        })
        .finally(() => {
          this.loading = false;
        });
    },
    getCategories() {
      this.fetching = true;
      this.$api
        .customRequest({
          method: "get",
          url: `/services/chatbot/pages/${this.$store.getters["page/id"]}/users/${this.$store.getters["user/id"]}/categories`,
          responseType: "json"
        })
        .then(({ data }) => {
          this.categories = data.categories.filter(
            cat => cat.id !== `root${this.$store.getters["page/id"]}`
          );
          this.rootCategories = this.categories.filter(node => {
            return node.parent_category === `root${this.$store.getters["page/id"]}`;
          });
        })
        .catch(error => {
          console.log(error);
        })
        .finally(() => {
          this.fetching = false;
        });
    }
  },
  created() {
    this.getCategories();
  }
};
</script>

<style lang="scss" scoped>
.m-section-title {
  text-align: center;
  font-weight: bold;
  font-size: 20px;
  margin: 20px auto 40px auto;
}
.tab-section {
  margin-top: 20px;
}
.pagination-control {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  button {
    &:first-child {
      i {
        margin-left: 10px;
      }
    }
    &:last-child {
      i {
        margin-right: 10px;
      }
    }
  }
}
</style>
